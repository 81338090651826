import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Cookie from '@/utils/Cookie'
import * as Utils from '@/utils/Util'
import HomeView from '../views/HomeView.vue'
import WechatView from '../views/WechatView.vue'
import AddStaffView from '@/views/AddStaffView.vue'
import AddTransInfo from '@/views/AddTransInfo.vue'
import ReportView from '@/views/ReportView.vue'
import ReportDetailView from '@/views/ReportDetailView.vue'
import { MetaProp } from '@/interface/router'
import ErrorView from '@/views/ErrorView.vue'
import BindUserView from '@/views/BindUserView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {
            navtab: true,
            title: '主界面',
            requiresAuth: true
        } as MetaProp
    },
    {
        path: '/add_trans_info',
        name: 'addTransInfo',
        component: AddTransInfo,
        meta: {
            navtab: true,
            title: '信息录入',
            requiresAuth: true
        } as MetaProp
    },
    {
        path: '/add_user',
        name: 'addUser',
        component: AddStaffView,
        meta: {
            navtab: true,
            title: '添加推广员',
            requiresAuth: true
        } as MetaProp
    },
    {
        path: '/reports',
        name: 'Reports',
        component: ReportView,
        meta: {
            title: '',
            navtab: false,
            requiresAuth: true
        } as MetaProp
    },
    {
        path: '/list',
        name: 'List',
        component: ReportDetailView,
        meta: {
            title: '业务清单',
            navtab: false,
            requiresAuth: true
        } as MetaProp
    },
    {
        path: '/wechat',
        name: 'Wechat',
        component: WechatView,
        meta: {
            title: '认证登录',
            navtab: false
        } as MetaProp
    },
    {
        path: '/error',
        name: 'Error',
        component: ErrorView,
        meta: {
            title: '账号异常',
            navtab: false
        } as MetaProp
    },
    {
        path: '/bind',
        name: 'BindUser',
        component: BindUserView,
        meta: {
            title: '用户验证',
            navtab: false
        } as MetaProp
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const userInfo = Utils.getUserInfo();
let isGatherer = false;
if (typeof userInfo !== 'boolean') {
    isGatherer = (userInfo.is_gatherer) ? true : false;
}

// 全局前置守卫
router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        window.document.title = to.meta.title;
    }
    // 检查路由是否需要登录权限
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // console.log("在路由中输出token:" + Cookie.getCookie('token'))
        // console.log("在路由中输出USER:" + localStorage.getItem('USER'))
        // 判断是否已登录（示例中使用 localStorage 存储登录状态）
        if (!Cookie.getCookie('token') || !localStorage.getItem('USER')) {
            // 未登录，重定向到登录页面
            next({ path: '/wechat' });
        } else {
            // 已登录，继续路由导航
            if (isGatherer) {
                if (to.path === '/' || to.path === '/add_user') {
                    next({ path: '/add_trans_info' });
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    } else {
        // 不需要登录权限，直接继续路由导航
        next();
    }
});

// function getDefaultMeta(): Pick<MetaProp, "navtab"> {
//     return {
//         navtab: true,
//     };
// }

export default router
