import User from './user'
import Staff from './staff'
import Business from './business'
import Report from './report'

export default {
    Business,
    User,
    Staff,
    Report
}