import request from "@/utils/Request";
import { AddStaffProp, StaffProp } from '@/interface/staff'

/**
 * 添加员工
 * @param username 员工姓名
 * @param mobile 员工手机号
 * @returns 
 */
function add(params: AddStaffProp): Promise<boolean> {
    return new Promise((resolve) => {
        request.post('/member/subordinate/add', { phone: params.mobile, name: params.username }).then(res => {
            if (res.error === 0) {
                resolve(true)
            } else {
                resolve(false)
            }
        });
    });
}

/**
 * 获取自己添加的推广员
 * @returns 
 */
function getAll(): Promise<StaffProp[] | boolean> {
    return new Promise((resolve) => {
        request.post('/member/subordinate/list_all').then(res => {
            if (res.error === 0) {
                resolve(res.data);
            } else {
                resolve(false);
            }
        });
    });
}

export default {
    getAll,
    add
}