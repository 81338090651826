/**
 * @description 保存cookie
 * @param {string} name 需要存储cookie的对象
 * @param {string} value 默认存储多少天
 * @param {number} second 有效时间,单位:天
 */
function setCookie(name: string, value: string | number | boolean, day: number) {
    let date:boolean|Date = false;
    // 设置过期时间
    if (day !== 0) {
        date = new Date();
        date.setDate(date.getDate() + day);
        // data = new Date(
        //     new Date().getDate() + day
        // ).toUTCString();
    }

    let cookie = name + "=" + value;
    if (date instanceof Date) {
        cookie += "; expires=" + date.toUTCString();
    }
    document.cookie = cookie;
}

/** 
 * @description 获取cookie
 * @param {String} name 需要获取cookie的key
 */
function getCookie(name: string) {
    const arr = document.cookie.match(new RegExp("(^| )" + name + "=([^;]*)(;|$)"));
    if (arr != null) {
        return decodeURIComponent(arr[2])
    } else {
        return null
    }
}

/** 
 * @description 删除cookie
 * @param {String} name 需要删除cookie的key
 */
function clearCookie(name: string) {
    document.cookie = `${name}=; expires=${new Date(0)};`;
}

export default {
    setCookie,
    getCookie,
    clearCookie
}
