import request from "@/utils/Request"
import { ReportProp, ListProp, LeveledListProp, MemberCountProp, RequestParamsProp } from '@/interface/report'
import { UserDetailProp } from '@/interface/user'
import { getTodayDate, getUserInfo } from "@/utils/Util";

/**
 * 获取当前用户今日累计报告
 */
function today(): Promise<ReportProp> {
    return new Promise((resolve) => {
        const today = getTodayDate();
        const user = getUserInfo() as UserDetailProp;
        const userLevel = user.level;

        request.post('count/total', {
            start_at: today,
            end_at: today
        }).then(res => {
            if (res.error === 0) {
                const data = res.data;
                const bord = res.data.leveled_list.map((item: LeveledListProp, index: number) => {
                    return {
                        level: userLevel + index,
                        count: item.sale_count,
                        kickback: item.expect_amount
                    }
                });
                resolve({
                    total: data.expect_amount,
                    board: bord
                });
            } else {
                resolve({
                    total: 0,
                    board: []
                });
            }
        });
    });
}

/**
 * 根据分组获取报表信息
 * 分组按level进行
 */
function getLevelReport(
    level: number | null = null,
    startDate: string,
    endDate: string): Promise<MemberCountProp[] | []> {
    return new Promise((resolve) => {
        request.post('count/list_of_members', {
            start_at: startDate,
            end_at: endDate,
            level: level
        }).then(res => {
            if (res.error === 0) {
                resolve(res.data)
            } else {
                resolve([]);
            }
        });
    });
}

//总列表
function all(): Promise<ReportProp> {
    return new Promise((resolve) => {
        const user = getUserInfo() as UserDetailProp;
        const userLevel = user.level;
        request.post('count/total', {}).then(res => {
            if (res.error === 0) {
                const data = res.data;
                const board = res.data.leveled_list.map((item: LeveledListProp, index: number) => {
                    return {
                        level: userLevel + index,
                        count: item.sale_count,
                        kickback: item.expect_amount
                    }
                });
                resolve({
                    total: data.expect_amount,
                    board: board
                });
            } else {
                resolve({
                    total: 0,
                    board: []
                });
            }
        });
    });
}

const list = (params: RequestParamsProp): Promise<ListProp | false> => {
    return new Promise(resolve => {
        request.post("/statement/list", params).then(res => {
            if (res.error === 0) {
                resolve(res.data);
            } else {
                resolve(false);
            }
        });
    })
}

export default {
    today,
    getLevelReport,
    all,
    list
}