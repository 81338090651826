import Cookie from './Cookie'
const errCodeToErrorView: number[] = [0xFFFFFFFB];
const errCodeToAuth: number[] = [0xFFFFFFFC, 0xFFFFFFFD, 0xFFFFFFFE, 0xFFFFFFFF];

export const inError = (code: number): void => {
    const currentURI = window.location.pathname;
    // console.log(currentURI);
    if (errCodeToErrorView.find(item => item === code)) {
        window.location.href = "/error";
    }
    if ([0xFFFFFFFE, 0xFFFFFFFF].includes(code)) {
        Cookie.clearCookie('token');
        window.location.href = '/wechat'
    }
    if (errCodeToAuth.find(item => item === code)) {
        if (currentURI !== '/wechat' && currentURI !== '/bind') {
            window.location.href = '/wechat'
        }
    }
}