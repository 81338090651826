import { UserDetailProp } from '@/interface/user'

export function verifyMobileNumber(mobile: string): boolean {
    if (!mobile) {
        return false;
    }
    const regex = /^1[3456789]\d{9}$/;
    return regex.test(mobile);
}

export function getUserInfo(): UserDetailProp | boolean {
    const userInfo = localStorage.getItem('USER');
    if (userInfo) {
        return JSON.parse(userInfo);
    } else {
        return false;
    }
}

export function setUserInfo(user: string): void {
    localStorage.setItem('USER', user);
}

export function hideMobile(mobile: string): string {
    return mobile.replace(/^(\d{3})\d{4}(\d{4})$/, "$1***$2");
}

//获取当日日期
export function getTodayDate(): string {
    const d = new Date();
    const year = d.getFullYear().toString();
    const month = d.getMonth() + 1;
    const day = d.getDate();
    return year + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day);
}

//数字千位转万位显示
export const countTrans = (count: number | undefined, currency = false): string => {
    count = (count === undefined) ? 0 : count;
    if (count >= 10000) {
        const res = count * 0.0001
        return parseInt(res.toString()) + "万";
    } else if (count === 0) {
        return "0";
    }
    if (!currency) {
        //如果不是货币
        return count.toString();
    } else {
        const res = CurrencyFormat(count);
        return (typeof res === 'number') ? res.toString() : res;
    }
}

//将货币格式化，保证小数点后的0能正常显示，一般传入的是number但是传出为string
export const CurrencyFormat = (currency: number): number | string => {
    // 检查数字是否为小数
    if (Number.isInteger(currency)) {
        // 如果是整数，则直接返回
        return currency.toString() + '.00';
    } else {
        // 如果是小数，则判断小数点后是否只有一位数字
        const strNum: string = currency.toString();
        const decimalIndex: number = strNum.indexOf('.');
        // 如果小数点后只有一位数字，则在末尾补充一个0  否则，直接返回原始数字
        return (strNum.substring(decimalIndex + 1).length === 1) ? strNum + '0' : currency;
    }
}