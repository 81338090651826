import request from "@/utils/Request";
import { WechatProp, EnvProp } from '@/interface/wechat'
import { UserDetailProp, MemberListProp } from '@/interface/user'
// import { RequestData } from '@/interface/request'

/**
 * 获取公众号相关配置信息
 */
function getWechatEnv(): Promise<WechatProp | boolean> {
    return new Promise((resolve) => {
        request.post('/wechat/oauth/params').then(res => {
            if (res.error === 0) {
                resolve({
                    "appid": res.data.appid,
                    "scope": res.data.scope,
                    "state": res.data.state
                });
            } else {
                resolve(false);
            }
        });
    });
}

//获取微信 token
export function getAuthToken(params: EnvProp): Promise<string | boolean> {
    return new Promise((resolve, reject) => {
        request.post('/login', params).then(res => {
            if (res.error === 0 && 'token' in res.data) {
                resolve(res.data.token);
            } else {
                resolve(false);
            }
        }).catch(err => {
            if (err.code === 'ECONNABORTED') {
                reject('timeout')
            }
        });
    });
}

function bind(phone: string, username: string): Promise<boolean> {
    return new Promise((resolve) => {
        request.post('/wechat/bind_member', { phone: phone, name: username }).then(res => {
            if (res.error === 0) {
                resolve(true);
            } else {
                resolve(false)
            }
        });
    });
}

/**
 * 拉取用户信息
 * @param userid 
 * @returns 
 */
function get(): Promise<UserDetailProp | false> {
    return new Promise((resolve) => {
        request.post('/profile').then(res => {
            if (res.error === 0) {
                resolve(res.data)
            } else {
                resolve(false)
            }
        });
    });
}

function memebers(): Promise<MemberListProp[] | false> {
    return new Promise((resolve) => {
        request.post('/member/list').then(res => {
            if (res.error === 0) {
                resolve(res.data);
            } else {
                resolve(false);
            }
        });
    });
}

export default {
    bind,
    get,
    getWechatEnv,
    getAuthToken,
    memebers
}