import axios, { AxiosInstance, AxiosResponse } from 'axios';
import Cookie from './Cookie';
import { useRouter } from 'vue-router'
import * as ErrorCode from '@/utils/ErrorCode'

interface CustomResponse<T> extends AxiosResponse {
    error: number | string,
    message: string,
    data: T;
}

// interface CustomRequestConfig extends AxiosRequestConfig {
//     // 可以在这里定义你的请求配置
// }

class Request {
    private instance: AxiosInstance;
    // private isMockEnabled: boolean;
    // mock!: MockAdapter;
    constructor() {
        this.instance = axios.create({
            // 配置你的基础 API 地址等
            baseURL: process.env.VUE_APP_API_BASE_URL,
            timeout: 10000, // 请求超时时间
            headers: {
                'Content-Type': 'application/json',
            },
        });

        // this.isMockEnabled = process.env.VUE_MOCK;
        // if (this.isMockEnabled) {
        //     this.setupMock();
        // }
        // 请求拦截器
        this.instance.interceptors.request.use((config) => {
            // 在发送请求之前可以进行一些操作，比如添加 token
            // config.headers.Authorization = 'Bearer ' + getToken();
            config.headers.Authorization = Cookie.getCookie('token');
            return config;
        }, (error) => {
            return Promise.reject(error);
        });

        // 响应拦截器
        this.instance.interceptors.response.use(
            (response) => {
                // 在响应之后可以进行一些操作，比如处理错误信息
                // 如果后端约定了错误格式，可以在这里统一处理
                ErrorCode.inError(response.data.error);
                return response.data;
            },
            (error) => {
                // 处理请求错误
                return Promise.reject(error);
            }
        );
    }

    // private setupMock() {
    //     this.mock = new MockAdapter(this.instance);

    //     // Loop through the mockData and set up mocks
    //     Object.keys(mockData).forEach((key) => {
    //         const [method, url] = key.split('|');
    //         const responseData = mockData[key];
    //         if (method === 'get') {
    //             this.mock.onGet(url).reply(200, responseData);
    //         } else if (method === 'post') {
    //             this.mock.onPost(url).reply(200, responseData);
    //         } else if (method === 'put') {
    //             this.mock.onPut(url).reply(200, responseData);
    //         } else if (method === 'delete') {
    //             this.mock.onDelete(url).reply(200, responseData);
    //         }
    //     });
    // }

    // 封装 GET 请求
    public get<T>(url: string, params?: object): Promise<CustomResponse<T>> {
        const token = Cookie.getCookie('token');
        if (!token) {
            const route = useRouter();
            route.push('/wechat');
        }
        return this.instance.get(url, { params });
    }

    // 封装 POST 请求
    public post(url: string, params?: any): Promise<any> {
        return this.instance.post(url, params);
    }
}

const request = new Request();

export default request;