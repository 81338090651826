import { createStore } from 'vuex'

export default createStore({
    state: {
        isGatherer: false
    },
    getters: {
    },
    mutations: {
        updateUserRole(state, payload) {
            state.isGatherer = payload
        }
    },
    actions: {
    },
    modules: {
    }
})
