import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vant from 'vant';
// import { ElTable, ElTableColumn, ElLoading } from 'element-plus'
import 'element-plus/dist/index.css'
import 'vant/lib/index.css';

try {
    createApp(App)
        // .use(ElTable)
        // .use(ElTableColumn)
        // .use(ElLoading)
        // .use(Vant)
        .use(store)
        .use(router)
        .mount('#app')
} catch (err) {
    console.log(err)
}