import request from "@/utils/Request";
import { BusinessProp, ProductOrgProp } from '@/interface/business'
import { UserProp } from '@/interface/user'
/**
 * 新增业务
 * @returns 
 */
function add(params: BusinessProp): Promise<boolean> {
    return new Promise((resolve, reject) => {
        request.post('sale/add', {
            customer_phone: params.customerMobile, //客户手机号
            customer_name: params.customer, //客户名称
            product_code: params.product_code, //产品代码
            gather_date: params.date, //办理时间
            member_id: params.member_id
        }).then(res => {
            if (res.error === 0) {
                resolve(true);
            } else {
                resolve(false)
            }
        }).catch(err => {
            reject(err)
        });
    });
}

/**
 * 获取产品信息
 * @returns Promies
 */
function products(): Promise<ProductOrgProp[]> {
    return new Promise((resolve, reject) => {
        request.post('product/list').then(res => {
            if (res.error === 0) {
                resolve(res.data);
                // const data: ProductProp[] = res.data.map((item: { name: any; code: any; }) => {
                //     return {
                //         text: item.name,
                //         value: item.code
                //     }
                // });
            } else {
                resolve([]);
            }
        }).catch(err => {
            reject(err);
        })
    })
}

interface historyListProp {
    id: number,
    customer_phone: string,
    customer_name: string,
    product: null | ProductOrgProp,
    member: null | UserProp,
    remark: string,
    created_at: string,
    gathered_at: string
}

interface historyAPIProp {
    list: historyListProp[],
    pager: {
        page: number,
        linage: number,
        all: number,
        all_page: number,
        overflow: boolean
    }
}
/**
 * 获取销售记录
 * @returns 
 */
function get(page: number, linage: number, date: string): Promise<historyAPIProp> {
    return new Promise((resolve, reject) => {
        request.post('/sale/self_list', { page: page, linage: linage, date: date }).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error);
        });
    });
}

function del(id: number) {
    return new Promise((resolve) => {
        request.post('/sale/remove', { id: id }).then(res => {
            // console.log(res)
            if (res.error === 0) {
                resolve(true);
            } else {
                resolve(false);
            }
        })
    });
}

export default {
    add,
    del,
    get,
    products
}